<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="Home"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('daiban') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <div>
        <v-card
          v-for="(item, index) in list"
          :key="'aplist'+index"
          flat
          class="my-3 rounded-xl"
        >
          <v-card-text>
            <v-list-item>
              <v-list-item-avatar tile>
                <v-icon
                  color="orange"
                  class="orange lighten-5 rounded-lg"
                >
                  mdi-cash
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('xiafen') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <img
                    width="16"
                    height="16"
                    src="../assets/img/icons/coin.svg"
                    class="mr-1"
                  >
                  <strong>{{ item.amount }}</strong>
                </div>
              </v-list-item-action>
            </v-list-item>

            <v-divider class="opacity-3" />

            <v-row
              dense
              class="pa-4"
            >
              <v-col cols="4">
                {{ $t('tijiao_shijian') }}
              </v-col>
              <v-col
                cols="8"
                class="text-right"
              >
                {{ item.created }}
              </v-col>

              <v-col cols="4">
                {{ $t('username') }}
              </v-col>
              <v-col
                cols="8"
                class="text-right"
              >
                {{ item.from_username }} ({{ item.from_role }})
              </v-col>

              <v-col cols="4">
                {{ $t('beizhu') }}
              </v-col>
              <v-col
                cols="8"
                class="text-right"
              >
                {{ item.remark }}
              </v-col>
            </v-row>

            <v-divider class="opacity-3" />

            <v-row class="mt-1">
              <v-col>
                <v-btn
                  outlined
                  block
                  rounded
                  color="primary"
                  @click="denydialog(item)"
                >
                  {{ $t('jujue') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  depressed
                  block
                  dark
                  rounded
                  color="primary"
                  @click="allowdialog(item)"
                >
                  {{ $t('tongguo') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div
          v-if="list.length==0"
          class="text-center text-body-2 opacity-3 pa-10"
        >
          <v-icon
            x-large
            class="my-2"
          >
            mdi-package-variant
          </v-icon>
          <div>
            {{ $t('zanwushuju') }}
          </div>
        </div>
      </div>
    </v-container>
    <v-dialog
      v-model="dialog1"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-text class="text-center pt-6 text-body-1">
          {{ $t('jujue_queren') }}
        </v-card-text>
        <v-divider class="opacity-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="dialog1=false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            dark
            @click="cashout_deny(theitem)"
          >
            {{ $t('tongguo') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-text class="text-center pt-6 text-body-1">
          {{ $t('tongguo_queren') }}
        </v-card-text>
        <v-divider class="opacity-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="dialog2=false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            dark
            @click="cashout_allow(theitem)"
          >
            {{ $t('tongguo') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: () => ({
      dialog1: false,
      dialog2: false,
      list: [],
      theitem: {}
    }),

    computed: {
      
    },
    created() {

    },
    mounted() {
      this.cashout_wait()
    },
    methods: {
      cashout_wait() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.cashout_wait(paramObj).then((response) => {
          if(response.code==200){
            this.list = response.data.list
          }
        })
      },
      allowdialog(item) {
        this.theitem = item
        this.dialog2 = true
      },
      cashout_allow(item) {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          order_id: item.id
        }
        this.$server.cashout_allow(paramObj).then((response) => {
          if(response.code==200){
            this.cashout_wait()
          }
        })
      },
      denydialog(item) {
        this.theitem = item
        this.dialog1 = true
      },
      cashout_deny(item) {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          order_id: item.id
        }
        this.$server.cashout_deny(paramObj).then((response) => {
          if(response.code==200){
            this.cashout_wait()
          }
        })
      },
    },
  }
</script>